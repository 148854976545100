import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  getCropTypes,
  getRvoCropfields,
  getSubfields,
  postSubfields,
} from 'adapters/cropfield-adapter';
import { redirectToRVO } from 'adapters/pvs-rvo-adapter';
import { message } from 'antd';
import { atoms } from 'atoms';
import { CustomButton, CustomCard } from 'components';
import { Container } from 'components/grid';
import { Feature } from 'geojson';
import { useAtom } from 'jotai';
import { useAtomValue } from 'jotai/utils';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { CropType } from 'types';
import { defaultSorter } from 'utilities/default-sorter';
import { MonsterCard } from './MonsterCard';
import { PlotCard } from './PlotCard';
import { PlotHeader } from './PlotHeader';
import { RvoCard } from './RvoCard';
import styles from './styles.module.scss';

export const Plots = () => {
  const history = useHistory();
  let { page = 'perceel' } =
    useParams<{ page: 'rvo' | 'perceel' | 'monsters' }>();
  const [sortBy] = useState<string>('name');
  const selectedYear = useAtomValue(atoms.selectedYear);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [plots, setItems] = useAtom(atoms.plots);
  const [extPlots, setExtPlots] = useState<Feature[]>([]);
  const [cropTypes, setCropTypes] = useState<CropType[]>([]);
  const [user] = useAtom(atoms.user);

  const setScreen = (screen: 'rvo' | 'perceel' | 'monsters') => {
    const searchParams = new URLSearchParams(window.location.search);
    const path = '/plots' + (screen === 'perceel' ? '' : '/' + screen);
    history.push({
      pathname: path,
      search: '?' + searchParams.toString(),
    });
  };

  useEffect(() => {
    getRvoCropfields({ year: selectedYear }).then((res) => setExtPlots(res));
    getCropTypes({ year: selectedYear }).then((res) => setCropTypes(res));
    getSubfields({ year: selectedYear }).then((res) => {
      setItems(res);
    });
  }, [selectedYear, setItems]);

  const checkSelected = (id: string) => selectedIds.includes(id);
  const addSelected = (id: string) =>
    setSelectedIds((current) => [...current, id]);
  const toggleSelected = (id: string) => {
    if (checkSelected(id)) removeSelected(id);
    else addSelected(id);
  };
  const removeSelected = (id: string) =>
    setSelectedIds((current) => current.filter((selected) => selected !== id));
  const selectAll = () =>
    setSelectedIds(extPlots.map((plot) => plot?.properties?.id));
  const getPlot = (id: string) =>
    extPlots.find((plot) => plot?.properties?.id === id);

  const savePlots = async (newPlots: Feature[]) => {
    const currentPlots = [...plots, ...newPlots];
    try {
      const response = await postSubfields(
        { year: selectedYear },
        currentPlots,
      );
      setItems(response);
      setScreen('perceel');
      message.success(
        'Geselecteerde percelen overgenomen naar gebruiksperceel',
      );
    } catch {
      message.error(
        'Het overnemen naar gebruikspercelen is mislukt. Probeer het opnieuw.',
      );
    }
  };

  const useRVOPlots = async () => {
    const list = await makeList();
    await savePlots(list);
  };

  const makeList = async () => {
    return selectedIds.map((id) => {
      const plot = getPlot(id) as Feature;
      plot.id = undefined;
      (plot.properties as any).id = undefined;
      (plot.properties as any).cropTypeId = (plot.properties as any).cropTypeId;
      (plot.properties as any).name = (plot.properties as any).name;
      (plot.properties as any).area = (plot.properties as any).area;
      (plot.properties as any).isClaimed = true;
      return plot;
    });
  };

  return (
    <>
      <PlotHeader
        screen={page || 'perceel'}
        setScreen={setScreen}
        selectAll={selectAll}
        useRVOPlots={useRVOPlots}
      />
      {user && page === 'rvo' && (
        <Container>
          <CustomButton
            color="primary"
            type="primary"
            shape="round"
            icon={<FontAwesomeIcon icon={faDownload} />}
            onClick={() => redirectToRVO(selectedYear, user.kvk)}
          >
            Haal mijn percelen op bij RVO
          </CustomButton>
        </Container>
      )}
      {page === 'monsters' ? (
        <Container>
          <CustomCard>
            <MonsterCard />
          </CustomCard>
        </Container>
      ) : (
        <div className={styles.vaaContent}>
          {page === 'rvo' && (
            <>
              {extPlots.map((plot) => (
                <RvoCard
                  checkSelected={checkSelected}
                  toggleSelected={toggleSelected}
                  plot={plot}
                />
              ))}
            </>
          )}

          {page === 'perceel' && (
            <>
              {plots
                .sort(
                  defaultSorter((plot) =>
                    sortBy === 'crop'
                      ? plot.properties?.crop
                      : plot.properties?.name,
                  ),
                )
                .map((plot) => (
                  <PlotCard item={plot} />
                ))}
            </>
          )}
        </div>
      )}
    </>
  );
};
