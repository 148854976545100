import { faCopy, faSortAmountDownAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Affix, Col, Menu, Row, Select, Space } from 'antd';
import { atoms } from 'atoms';
import { CustomButton } from 'components';
import { LinkWithQuery } from 'components/link-with-query';
import { useAtomValue } from 'jotai/utils';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';

const { Option } = Select;

const getPopupContainer: (props: any) => HTMLElement = () =>
  document.getElementById('dropdownarea') || document.body;

export interface PlotHeaderProps {
  setScreen: (screen: 'rvo' | 'perceel' | 'monsters') => void;
  selectAll: () => void;
  useRVOPlots: () => void;
  screen: 'rvo' | 'perceel' | 'monsters';
}
export const PlotHeader: FC<PlotHeaderProps> = ({
  screen,
  setScreen,
  selectAll,
  useRVOPlots,
}) => {
  const { t } = useTranslation();

  const [sortBy, setSortBy] = useState<string>('name');
  const breakpoint = useAtomValue(atoms.breakpoint);
  return (
    <>
      <Affix offsetTop={breakpoint.offset}>
        <div
          style={{
            backgroundColor: '#fff',
            transform: 'translateY(-50px)',
            height: 100,
            paddingLeft: 24,
            paddingRight: 24,
            boxShadow: '0px 2px 5px #ccc',
          }}
        >
          <Row
            style={{
              transform: 'translateY(50px)',
            }}
          >
            <Col span={3}>
              <h2>{t(`plots.${screen}`)}</h2>
            </Col>
            <Col span={7}>
              <Menu
                style={{ lineHeight: '70px', transform: 'translateY(-20px)' }}
                mode="horizontal"
                selectedKeys={[screen + '']}
              >
                <Menu.Item key="perceel" onClick={() => setScreen('perceel')}>
                  Gebruikspercelen
                </Menu.Item>
                <Menu.Item key="rvo" onClick={() => setScreen('rvo')}>
                  RVO percelen
                </Menu.Item>
                <Menu.Item key="monsters" onClick={() => setScreen('monsters')}>
                  Bodemmonsters
                </Menu.Item>
              </Menu>
            </Col>
            <Col style={{ textAlign: 'right', paddingRight: 5 }} span={14}>
              <Space>
                {screen === 'rvo' && (
                  <>
                    <CustomButton
                      color="secondary"
                      type="primary"
                      shape="round"
                      icon={<FontAwesomeIcon icon="plus" />}
                      onClick={selectAll}
                    >
                      Selecteer allen
                    </CustomButton>
                    <CustomButton
                      color="primary"
                      type="primary"
                      shape="round"
                      icon={<FontAwesomeIcon icon={faCopy} />}
                      onClick={useRVOPlots}
                    >
                      Neem geselecteerde percelen over als gebruiksperceel
                    </CustomButton>
                  </>
                )}
                {screen === 'perceel' && (
                  <>
                    {breakpoint.md && (
                      <Select
                        value={sortBy}
                        onChange={setSortBy}
                        className={styles.sortBy}
                        getPopupContainer={getPopupContainer}
                      >
                        <Option value="name">
                          <FontAwesomeIcon
                            icon={faSortAmountDownAlt}
                          ></FontAwesomeIcon>
                          &nbsp;&nbsp; Naam
                        </Option>
                        <Option value="crop">
                          <FontAwesomeIcon
                            icon={faSortAmountDownAlt}
                          ></FontAwesomeIcon>
                          &nbsp;&nbsp; Gewas
                        </Option>
                      </Select>
                    )}
                    <LinkWithQuery to="/map">
                      <CustomButton
                        color="primary"
                        type="primary"
                        shape="round"
                        icon={<FontAwesomeIcon icon="map" />}
                      >
                        Percelen aanpassen
                      </CustomButton>
                    </LinkWithQuery>
                  </>
                )}
              </Space>
            </Col>
          </Row>
        </div>
      </Affix>
    </>
  );
};
